@import url(https://fonts.googleapis.com/css?family=Jost:100,200,300,regular,500,600,700,800,900,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic);



body {
  margin: 0;
  font-family: "Jost", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body::-webkit-scrollbar {
  display: none;
}

html,
body {
  background-image: url('./images/BGImage.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  scroll-behavior: smooth;
}

/* Your Tailwind CSS styles */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Font color: 6 parts */
.text-primary {
  color: #FFFFFF; /* Replace with your desired font color */
}

/* Background color: 3 parts */




/* Background color: 1 part */


#home {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

}


/* Your existing CSS styles go here */

/* Media query for mobile devices */
@media (max-width: 767px) {
  body {
    background-image: url('./images/BGImageMobile.webp');
    background-size: contain;
    background-position: center; /* Add this line */
    background-repeat: no-repeat; /* Add this line */
    scroll-behavior: smooth;
    background-attachment: fixed;
  }
}

.achievement {
  color: rgb(150, 244, 150);
  font-family: Helvetica;
}

.desc {
  color: rgb(250, 237, 237);
  font-family: Helvetica;
}